<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 15:49:15
-->
<template>
  <div class="grabSingle">
    <div class="grab_img">
      <img :src="$utils.picturePrefix(banner)" alt="" />
    </div>
    <div class="container">
      <div class="headline">
        <span>
          <img
            style="height: 15px"
            src="../../assets/img/grabSingle/img-information-left.png"
            alt=""
          />
        </span>
        <span class="title">抢单大厅</span>
        <span>
          <img
            style="height: 15px"
            src="../../assets/img/grabSingle/img-information-rigth.png"
            alt=""
          />
        </span>
      </div>
      <div class="allot">
        <img
          style="width: 57px; height: 100%"
          src="../../assets/img/grabSingle/img-information-center.png"
          alt=""
        />
      </div>
      <span class="serial">海量订单任你选</span>
      <span class="rail"></span>
      <div class="merchandise" v-show="isShowSearch">
        <!--  @change="handleChange" :props="{value:'name'}" -->
        <!-- 地区 -->
        <div class="merchandise-item">
          <span class="iconfont icon-f-location"></span>
          <el-cascader
            :append-to-body="false"
            placeholder="全国"
            filterable
            :props="{ checkStrictly: true }"
            v-model="address"
            separator=" "
            :options="$store.getters.address"
            @change="addressChange"
            popper-class="address-popper-class"
          ></el-cascader>
        </div>

        <!-- 安装单类别 -->
        <div class="merchandise-item">
          <span
            class="iconfont icon-quxiao"
            v-show="search_form.type != ''"
            @click="
              search_form.type = '';
              changeList();
            "
          ></span>
          <el-select
            :popper-append-to-body="false"
            v-model="search_form.type"
            placeholder="安装单类别"
            @change="changeList"
          >
            <el-option label="安装单" :value="1"></el-option>
            <el-option label="配件单" :value="2"></el-option>
          </el-select>
        </div>
        <!-- 价格区间 -->
        <div class="merchandise-item">
          <div class="price-input">
            <!-- <el-input
              type="number"
              v-model="search_form.starPrice"
              placeholder="最低价"
              @blur="changeList"
            ></el-input> -->
            <el-input-number
              v-model="search_form.starPrice"
              :value="search_form.starPrice"
              :precision="2"
              placeholder="最低价"
              @blur="changeList"
            ></el-input-number>
            -
            <el-input-number
              v-model="search_form.endPrice"
              :precision="2"
              placeholder="最高价"
              @blur="changeList"
            ></el-input-number>
          </div>
        </div>

        <!-- 产品分类 -->
        <div class="merchandise-item">
          <span
            class="iconfont icon-quxiao"
            v-show="search_form.productType != ''"
            @click="
              search_form.productType = '';
              changeList();
            "
          ></span>
          <el-select
            :popper-append-to-body="false"
            v-model="search_form.productType"
            placeholder="产品分类"
            @change="changeList"
          >
            <el-option
              v-for="item in product_type"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>

        <!-- 日期  -->
        <div class="merchandise-item">
          <el-date-picker
            :append-to-body="false"
            v-model="search_form.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            @change="changeList"
          >
          </el-date-picker>
        </div>

        <div style="flex: 2.5" class="merchandise-item-opt">
          <span style="float: right" @click="rest">清空筛选</span>
        </div>
      </div>
      <div class="indent_list" v-show="list && list.length > 0">
        <div class="item card" v-for="(item, index) in list" :key="index">
          <div class="install">
            <div class="install-left">
              <div class="press">
                {{ item.type == 1 ? "安装" : "配件" }}
              </div>
              <span class="text">编号：{{ item.orderNum }}</span>
              <span class="text">发布时间：{{ item.createDate }}</span>
            </div>
            <!-- <span
              slot="reference"
              class="keep iconfont icon-all_baozhang"
            ></span> -->
            <el-popover
              popper-class="popperOptions"
              placement="bottom-start"
              trigger="hover"
            >
              <div style="color: red">* 安裝维修质保30天</div>
              <span
                slot="reference"
                class="keep iconfont icon-all_baozhang"
              ></span>
            </el-popover>
          </div>
          <div class="site">客户地址:{{ item.clientAddress }}</div>
          <div class="goods">
            <div class="picture">
              <img
                style="width: 80px; height: 80px"
                :src="$utils.picturePrefix(item.productImage)"
                alt=""
              />
            </div>
            <div class="details">
              <div class="brand ellipsis_one">{{ item.productName }}</div>
              <div class="price">
                <span class="income" style="font-size: 18px; color: #ff0000"
                  >预计收入：&#65509;{{ item.money }}</span
                >
                <el-button class="grab" size="mini" @click="go_detail(item.id)"
                  >立即抢单</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <empty v-show="!list || list.length <= 0" description="暂无数据"></empty>

      <u-pagination
        ref="uPagination"
        :total="total"
        @change="pagin_change"
        v-if="total != 0"
      ></u-pagination>
    </div>
    <tip-modal :visible="visible" @close="visible = false" @ok="save">
      <div>
        接单<span style="color: red">2个小时</span>
        后, 师傅仍未预约, 系统将回收订单
      </div>
    </tip-modal>
  </div>
</template>

<script>
import TipModal from "@/components/TipModal";
import { mapGetters } from "vuex";
import UPagination from "@/components/UPagination";
import Empty from "@/components/Empty";
let ids = "";
export default {
  components: {
    UPagination,
    Empty,
    TipModal
  },
  data() {
    return {
      city: [],
      list: [],
      // 配件
      tool_list: [
        {
          name: "安装",
          id: "1"
        },
        {
          name: "配件",
          id: "2"
        }
      ],
      specification_list: [],
      // 产品列席
      product_type: [],
      date_list: [],
      price: "", // 价格区间
      address: [],
      // 合肥市
      search_form: {
        address: "", // 地址
        date: "", // 时间
        type: "", // 安装单类别1安装单 2配件单
        productType: "", // 产品分类名称
        starPrice: undefined, // 开始价格
        endPrice: undefined, // 结束价格
        num: 10, //页数
        page: 1 // 页码
      },
      total: 1,
      banner: "",
      visible: false,
      margin: ""
    };
  },
  created() {
    this.rest();
    this.query_getlist();
    this.query_select();
    this.query_banner();
    // this.get_addressCode(this.$store.getters.address)
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["isPower"]),
    getAddress() {
      return this.$store.getters.address || [];
    },
    getUser() {
      return this.$store.getters.user;
    },
    isPower() {
      return this.$store.getters.isPower;
    },
    isShowSearch() {
      let user = this.$store.getters.user;
      if (user && user.isPartner) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.isPower) {
      this.getSigningProcess();
    }
  },
  methods: {
    bannerHeight() {
      let width = window.innerWidth;
      let getClass = document.getElementsByClassName("grab_img")[0];
      // eslint-disable-next-line no-self-assign
      width < 1200 ? (width = 1200) : (width = width);
      let height = (width / 1920) * 360;
      console.log(getClass, height);
      return (getClass.style.height = height + "px");
    },
    getSigningProcess() {
      this.$api.getSigningProcess().then(res => {
        this.margin = res.data.margin;
      });
    },

    // get_addressCode(arr){
    //   console.log(arr);
    //   // arr.forEach(e => {
    //   //   console.log(e.value);
    //   //   this.address.push(e.value)
    //   //   if(e.children){
    //   //      this.get_addressCode(e.children)
    //   //   }
    //   // })
    // },
    // 跳转至抢单大厅详情页
    go_detail(id) {
      ids = id;
      if (this.isPower) {
        this.$api
          .isArea({
            id: id,
            userId: this.getUser.id
          })
          .then(res => {
            if (this.getUser && this.getUser.isPartner) {
              let margin = this.getUser.margin * 100;
              let margin1 = this.margin * 100 * 0.8;
              if (margin > margin1) {
                this.$router.push(`/grab-single/detail?id=${ids}`);
              } else {
                this.$alert(
                  "保证金余额不足,请联系管理员进行充值: " +
                    `${sessionStorage.getItem("contactPhone") || ""}`,
                  "提示",
                  {
                    confirmButtonText: "确定"
                    // callback: action => {
                    //   // this.$message({
                    //   //   type: "info",
                    //   //   message: `取消抢单!`
                    //   // });
                    // }
                  }
                );
              }
            } else {
              this.$message.error("您还不是城市合伙人，请联系管理员");
            }
          })
          .catch(err => {
            this.$message.error(err?.msg);
          });
      } else {
        this.$message.error("请登录");
        this.$router.replace("login");
      }
    },
    save() {
      this.$router.push(`/grab-single/detail?id=${ids}`);
    },
    changeList() {
      let search_form = { ...this.search_form };
      if (!isNaN(search_form.starPrice) && !isNaN(search_form.endPrice)) {
        if (search_form.starPrice > search_form.endPrice) {
          let price = search_form.starPrice;
          search_form.starPrice = search_form.endPrice;
          search_form.endPrice = price;
        }
      }
      search_form.page = 1;
      this.search_form = search_form;
      this.$refs.uPagination.setData(
        this.search_form.page,
        this.search_form.num
      );
      // console.log("endPrice", this.search_form.endPrice);
      this.query_getlist();
    },
    addressChange(addressCodes) {
      let area = [...addressCodes];
      let addressArr = [...this.getAddress];
      let addressStr = "";
      area.map(item => {
        let addressJson = addressArr.find(nape => {
          return nape.value == item;
        });
        if (addressJson) {
          addressStr += addressJson.label;
          if (addressJson.children) {
            addressArr = [...addressJson.children];
          }
        }
      });
      this.search_form.address = addressStr;
      this.changeList();
    },
    // 抢单列表
    query_getlist() {
      let obj = {
        ...this.search_form,
        num: this.search_form.num, //页数
        page: this.search_form.page
      };
      // console.log("--", obj.starPrice);

      if (
        obj.starPrice == undefined &&
        obj.starPrice == null &&
        obj.starPrice == ""
      ) {
        obj.starPrice = "";
      }
      if (
        obj.endPrice == undefined &&
        obj.endPrice == null &&
        obj.endPrice == ""
      ) {
        obj.endPrice = "";
      }
      // if (Array.isArray(this.search_form.address)) {
      //   this.search_form.address = this.search_form.address.join(",");
      // }
      // for (let k in this.search_form) {
      //   if (this.search_form[k].length > 0) {
      //     obj[k] = this.search_form[k];
      //   }
      // }
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getInstallationOrder(obj)
        .then(res => {
          let {
            code,
            data: { records, total }
          } = res;

          if (code == 200) {
            this.total = total;
            this.list = records;
          }
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    // 规格 & 产品分类 下拉框
    query_select() {
      this.$api.getInstallationOrderType().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.specification_list = data;
          this.product_type = data;
        }
      });
    },
    // 全国地区显示
    handleChange(value) {
      console.log(value);
    },
    // 清空选择
    rest() {
      this.address = [];
      this.search_form.address = "";
      this.search_form.date = "";
      this.search_form.type = "";
      this.search_form.productType = "";
      this.search_form.starPrice = undefined;
      this.search_form.endPrice = undefined;
      this.search_form.page = 1;
      // this.$refs.uPagination.setData(
      //   this.search_form.page,
      //   this.search_form.num
      // );
      this.query_getlist();
    },
    // 分页方法
    pagin_change(page, pageSize) {
      this.search_form.page = page;
      this.search_form.num = pageSize;
      this.query_getlist();
    },
    //  背景图
    query_banner() {
      this.$api.getBanner({ type: 9 }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.banner = data[0].image;
        }
      });
    }
  }
};
</script>

<style lang="less" scope>
@import "./index.less";
</style>
